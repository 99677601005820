import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Section from "../components/Section/Section"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Section>
      <SEO title="404: Not found" />
      <h1>Uuups &ndash; Seitenfehler</h1>
      <p>Sieht so aus, als ob die Seite leider nicht gefunden werden konnte.</p><br />
      <p>Hier kommen Sie zur <Link to="/">Startseite</Link> zurück.</p><br />
    </Section>
  </Layout>
)

export default NotFoundPage
